.main {
  width: 100%;
  height: 100vh;
}

video {
  width: 100%;
  height: 100%;
  background-color: rgba(0,0,0,1);
  /* object-fit: cover; */
}
.videoCover{
  object-fit: cover;
}
.content {

  position: absolute;
  width: 100%;
  height: 100%;
  top:0;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  color: white;
}
.overlay {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0,0,0,.4);
}